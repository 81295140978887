<template>
  <div class="basicInformation-main">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>

    <div class="form">
      <div class="form-main">
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span
              >初始密码：</span
            >
          </div>
          <el-input
            v-model="currentPassword"
            placeholder="请输入初始密码"
            type="password"
            autocomplete="off"
            :readonly="readonly"
            @focus="handlerIptClick"
              maxlength="1000"
          ></el-input>
        </div>
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span
              >新密码：</span
            >
          </div>
          <el-input
            type="password"
            v-model="newPassword"
              maxlength="1000"
            placeholder="请输入新密码"
          ></el-input>
        </div>
        <div class="box">
          <div class="title">
            <img src="@/assets/images/income/star.png" alt="图片" /><span
              >确认密码：</span
            >
          </div>
          <el-input
            placeholder="请输入确认密码"
            v-model="confirmPassword"
            type="password"
              maxlength="1000"
          ></el-input>
        </div>
        <div class="button-box">
          <el-button @click="submit">保存</el-button>
        </div>
      </div>

      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import Footer from "@/components/Footer/homePageFooter.vue";
import { setChangePassword } from "@/api/user";
import { removeStorage } from "@/utils/auth";
export default {
  components: { Breadcrumb, Footer },
  data() {
    return {
      list: [
        {
          name: "账号设置",
          path: null,
        },
        {
          name: "密码设置",
          path: null,
        },
      ],
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      readonly: true,
    };
  },
  created() {},
  methods: {
    submit() {
      let params = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      };
      setChangePassword(params).then((res) => {
        console.log(1233, res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "密码修改成功",
            type: "success",
          });
          this.$router.push("/login?loginType=3");
          removeStorage("token");
        }
      });
    },
    handlerIptClick() {
      this.readonly = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.basicInformation-main {
  padding: 20px 0 0 20px;
  min-height: calc(100% - 20px);
  .form {
    min-height: calc(100vh - 153px);
    background-color: #fff;
    margin-top: 20px;
    position: relative;
    .form-main {
      display: flex;
      flex-direction: column;
      padding: 65px 300px 0 300px;
      align-items: center;
      .box {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 35px;
        .title {
          width: 120px;
          color: #06052b;
          margin-right: 10px;
          display: flex;
          justify-content: flex-end;
          img {
            widows: 12px;
            height: 12px;
          }
          span {
            font-size: 14px;
            color: #06052b;
          }
        }
        .el-select {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .el-input {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .el-cascader {
          width: calc(100% - 100px);
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
      }
      .button-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 60px;
        .el-button {
          float: left;
          width: 100px;
          height: 36px;
          background: #4578ff;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
    .footer-fa {
      position: absolute;
      bottom: 0;
      padding-right: 20px;
      width: calc(100% - 20px);
      background-color: #fff;
      margin-top: 150px;
      /deep/ .home-page-footer {
        margin-bottom: 0 !important;
        padding-bottom: 25px;
      }
    }
  }
}
</style>